exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-free-assistance-program-for-corona-affected-japan-schools-en-js": () => import("./../../../src/pages/blog/free-assistance-program-for-corona-affected-japan-schools.en.js" /* webpackChunkName: "component---src-pages-blog-free-assistance-program-for-corona-affected-japan-schools-en-js" */),
  "component---src-pages-blog-free-assistance-program-for-corona-affected-japan-schools-ja-js": () => import("./../../../src/pages/blog/free-assistance-program-for-corona-affected-japan-schools.ja.js" /* webpackChunkName: "component---src-pages-blog-free-assistance-program-for-corona-affected-japan-schools-ja-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contact-us-ja-js": () => import("./../../../src/pages/contact-us.ja.js" /* webpackChunkName: "component---src-pages-contact-us-ja-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-ja-js": () => import("./../../../src/pages/faq.ja.js" /* webpackChunkName: "component---src-pages-faq-ja-js" */),
  "component---src-pages-how-to-setup-parent-en-js": () => import("./../../../src/pages/how-to-setup-parent.en.js" /* webpackChunkName: "component---src-pages-how-to-setup-parent-en-js" */),
  "component---src-pages-how-to-setup-parent-ja-js": () => import("./../../../src/pages/how-to-setup-parent.ja.js" /* webpackChunkName: "component---src-pages-how-to-setup-parent-ja-js" */),
  "component---src-pages-how-to-setup-school-en-js": () => import("./../../../src/pages/how-to-setup-school.en.js" /* webpackChunkName: "component---src-pages-how-to-setup-school-en-js" */),
  "component---src-pages-how-to-setup-school-ja-js": () => import("./../../../src/pages/how-to-setup-school.ja.js" /* webpackChunkName: "component---src-pages-how-to-setup-school-ja-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-ja-js": () => import("./../../../src/pages/privacy-policy.ja.js" /* webpackChunkName: "component---src-pages-privacy-policy-ja-js" */),
  "component---src-pages-terms-of-service-en-js": () => import("./../../../src/pages/terms-of-service.en.js" /* webpackChunkName: "component---src-pages-terms-of-service-en-js" */),
  "component---src-pages-terms-of-service-ja-js": () => import("./../../../src/pages/terms-of-service.ja.js" /* webpackChunkName: "component---src-pages-terms-of-service-ja-js" */),
  "component---src-pages-videos-en-js": () => import("./../../../src/pages/videos.en.js" /* webpackChunkName: "component---src-pages-videos-en-js" */),
  "component---src-pages-videos-ja-js": () => import("./../../../src/pages/videos.ja.js" /* webpackChunkName: "component---src-pages-videos-ja-js" */)
}

